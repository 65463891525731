import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import BeforeAfterHistory from '../report/beforeAfterHistory';

function BeforAfters() {
  return (
    <div className={`collapse collapse-arrow bg-base-200 `}>
      <input type="checkbox" id="main-report" defaultChecked />
      <div className="collapse-title text-xl font-medium">
        تصاویر پیش و پس از دوره
      </div>
      <BeforeAfterHistory />
    </div>
  );
}

export default BeforAfters;
