import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ProductData from '../Dashboard/userData/sessions';
import SizeHistory from '../../component/weightChart';
import CoachReport from './coachReport';
import api from '../../api/api';
import { useNavigate } from 'react-router-dom';
import getUserData from '../../api/getUserInitialData';
import getUserProgress from '../../api/userScore';
import persianNumbers from '../../api/persianNumber';
import { FaRankingStar } from 'react-icons/fa6';

function UserIndex(props) {
  const [userId, setUserId] = useState(null);
  const { hasReport, coachReport, allCoachReport } = props;
  const userData = useSelector((state) => state.user);
  const [userDetails, setUserDetails] = useState();
  const [proteinAmount, setProteinAmount] = useState(0);
  const [fatAmount, setFatAmount] = useState(0);
  const [carbsAmount, setCarbsAmount] = useState(0);
  const [status, setStatus] = useState('idle');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [userProgress, setUserProgess] = useState({
    totalScore: 0,
    totalPassed: 0,
    totalFailed: 0,
  });
  let navigate = useNavigate();
  const calculateGrams = () => {
    // Calculate calories for each macronutrient
    const proteinCalories =
      (coachReport?.protein / 100) * coachReport?.calories;
    const fatCalories = (coachReport?.fat / 100) * coachReport?.calories;
    const carbsCalories = (coachReport?.carbs / 100) * coachReport?.calories;

    // Calculate grams for each macronutrient
    setProteinAmount(proteinCalories / 4); // 4 calories per gram of protein
    setFatAmount(fatCalories / 9); // 9 calories per gram of fat
    setCarbsAmount(carbsCalories / 4); // 4 calories per gram of carbs
  };

  useEffect(() => {
    if (coachReport) {
      calculateGrams();
    }
  }, [coachReport]);

  const handleUserProgress = async () => {
    try {
      const res = await getUserProgress(userId);
      console.log('rrreess', res);
      setUserProgess(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleUserProgress();
  }, []);

  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    if (credentials) {
      const parsedCredentials = JSON.parse(credentials);
      setUserDetails(parsedCredentials);
      setUserId(parsedCredentials.userId);

      console.log(userId);
      setStatus('loading');

      // Send a GET request to check if the user already has assessment data
      api
        .get(`/ShapeUpAssessment/${parsedCredentials.userId}`)
        .then((res) => {
          console.log('res', res);
          if (res.data && res.data.data.length > 0) {
            // setUserData(res.data.data[0]); // Assuming res.data contains the user assessment data
          }
          setStatus('loaded');
        })
        .catch((error) => {
          console.log('No existing data found', error);
          alert('لطفا فرم اطلاعات اولیه خود را تکمیل کنید');
          navigate('/dashboard/basicData');
        });
    }
  }, []);

  const getUser = async () => {
    try {
      const res = await getUserData(userId);
      setUserName(res.name);
      setEmail(res.email);
    } catch {
      console.log('no userID');
    }
  };

  useEffect(() => {
    getUser();
  }, [userId]);

  return (
    <div className="p-6">
      <div className="p-8 bg-white shadow mt-1">
        <div className="grid grid-cols-1 md:grid-cols-2">
          <p> سلام {userName ? userName : email} عزیز</p>
          <p className="flex flex-row gap-2">
            <FaRankingStar color="#FBBF24" size={24} />
            شما تاکنون {persianNumbers(userProgress?.totalScore)} امتیاز کسب
            کرده اید
          </p>
        </div>
        <div className="mt-20  border-b pb-12">
          {/* {userId} */}
          <div className="flex flex-col lg:flex-row md:max-lg:flex md:justify-between mt-5">
            {hasReport && (
              <CoachReport
                allCoachReport={allCoachReport}
                coachReport={coachReport}
                proteinAmount={Math.round(proteinAmount)}
                fatAmount={Math.round(fatAmount)}
                carbsAmount={Math.round(carbsAmount)}
                maintainCalorie={coachReport.maintainCalorie}
                className="lg:w-1/2 md:max-lg:flex lg:mr-5 mb-5 md:mb-0"
              />
            )}
            {/* <SizeHistory userId={userId} className="lg:w-1/2 md:max-lg:flex" /> */}
          </div>
          {props?.userProducts && (
            <ProductData userProducts={props.userProducts} />
          )}
        </div>
        <div className="mt-12 flex flex-col justify-center"></div>
      </div>
    </div>
  );
}

export default UserIndex;
